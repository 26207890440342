import React from 'react';
import * as styles from './ContactBox.module.css';
import { Balloon } from './images';

const ContactBox = () => {
    return(
        <div className={styles.contactBoxWrapper}>
            <div className={styles.contactBox}>
                <h2 className={styles.contactBoxTitle}>Branislav <span className={styles.contactBoxTitlePrimary}>Ballon</span></h2>
                <ul className={styles.contactList}>
                    <li className={styles.contactListItem}><a className={styles.contactListLink} href="mailto:branislav.ballon@gmail.com">branislav.ballon@gmail.com</a></li>
                    <li className={styles.contactListItem}><a className={styles.contactListLink} href="https://www.linkedin.com/in/branislav-ballon-81955885/">LinkedIn</a></li>
                    <li className={styles.contactListItem}><a className={styles.contactListLink} href="https://www.facebook.com/branislav.ballon/">facebook</a></li>
                </ul>
            </div>
            <div className={styles.balloonImage}>
                <Balloon bounce/>
            </div>
        </div>
    )
}


export default ContactBox;