import React, {useRef} from "react";
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import ContactBox from '../components/ContactBox';
import {PageTitle, Container, Row, Col, FollowingBallonContainer}  from '../components/visual';
import * as styles from './contact.module.css';

const ContactPage = () => {
    const containerRef = useRef();

    return  (
        <Layout>
            <Seo title="Kontakt"/>
            <Container>
                <PageTitle>Kontakt</PageTitle>
                    <Row justifyContent="center">
                        <Col xs="11" sm="7" md="5">
                            <div className={styles.box}>
                                <ContactBox />
                            </div>
                        </Col>
                    </Row>
            </Container>
        </Layout>
    )
}

export default ContactPage;